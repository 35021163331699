import styled from '@emotion/styled'
import { useFeatureFlags } from 'cuenect-web-core'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentContainer } from '../components'
import {
  BigCountdown,
  Button,
  ContentColumn,
  Heading,
  KeyVisual,
  LineContainer,
  Paragraph,
  SieArrow,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { siemensTheme } from './../themes/siemens/siemensTheme'
import { siemensThemeDarkHeader } from './../themes/siemens/siemensThemeDarkHeader'
import { mq, Spacer, useGlobalContext, useVodContext } from './../utility'

import { isPostEvent, isPostEventFirst } from './../config'

const IndexPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const { dispatch: openVod } = useVodContext()
  const {
    state: { status: gobalStatus },
  } = useGlobalContext()
  const [loaded, setLoaded] = React.useState(false)
  const [countdownCompleted, setCountdownCompleted] = React.useState(false)
  const { phase2, phase3 } = useFeatureFlags()
  const postEvent = isPostEvent()

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en${document.location.search}`)
    }

    return <></>
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const { t } = useTranslation('index')

  return (
    <PageFrame
      {...params}
      theme={
        !gobalStatus?.mobileMenuOpen ? siemensThemeDarkHeader : siemensTheme
      }
      pageName="index"
      isTop
    >
      {loaded && (
        <>
          <KeyVisual>
            <ContentContainer fullheight>
              <StartTeaser fullheight width={50}>
                <LineContainer className="LineContainer">
                  <Heading dark type="h6" spacing={{ bottom: 0.8 }}>
                    {t('topline')}
                  </Heading>
                  <Heading dark>{parse(t('header'))}</Heading>
                </LineContainer>
              </StartTeaser>
            </ContentContainer>
          </KeyVisual>

          <Spacer />
          {!countdownCompleted && (
            <>
              <ContentContainer>
                <BigCountdown
                  onComplete={() => setCountdownCompleted(true)}
                  headline={<Heading type="h4">{t('counter.header')}</Heading>}
                />
              </ContentContainer>
              <Spacer />
            </>
          )}
          <ContentContainer align="center">
            <StartTeaser width={66}>
              <LineContainer className="LineContainer">
                <Heading type="h2">{parse(t(`teaser.header`))}</Heading>
              </LineContainer>
              <Paragraph>
                {parse(t(`teaser.copy${postEvent ? 'PostEvent' : ''}`))}
              </Paragraph>

              {!phase2 && !postEvent && (
                <Button onClick={() => navigate(`/en/program/stage`)}>
                  {t('teaser.agenda')}
                </Button>
              )}

              {phase2 && !postEvent && (
                <Button
                  onClick={() => {
                    if (isPostEventFirst()) {
                      window.open(
                        'https://new.siemens.com/global/en/company/fairs-events/smart-buildings-connect/get-in-touch-with-us.html'
                      )
                    } else {
                      navigate(`/en/dialog#appointment`)
                    }
                  }}
                >
                  {t(
                    isPostEventFirst()
                      ? 'teaser.getInTouch'
                      : 'teaser.makeAppointment'
                  )}
                </Button>
              )}

              {phase2 && postEvent && (
                <Button onClick={() => navigate(`/en/infomaterial`)}>
                  {t(`teaser.informationMaterial`)}
                </Button>
              )}
            </StartTeaser>
          </ContentContainer>
          <Spacer />
          <ContentContainer>
            <ContentColumn
              left={
                <img
                  src={t('row1.visual')}
                  style={{ cursor: 'pointer' }}
                  onMouseOver={e =>
                    (e.currentTarget.src = t('row1.visualHover'))
                  }
                  onMouseOut={e => (e.currentTarget.src = t('row1.visual'))}
                  onClick={() =>
                    openVod({
                      type: 'OPEN_VOD',
                      src: t(`row1.button.src`),
                      trackingTitle: t(`row1.button.title`),
                    })
                  }
                />
              }
              right={
                <div>
                  <Heading type="h4">{t('row1.header')}</Heading>
                  <Paragraph>
                    {parse(t(`row1.copy${postEvent ? 'PostEvent' : ''}`))}
                  </Paragraph>
                  {t('row1.button') && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() =>
                        openVod({
                          type: 'OPEN_VOD',
                          src: t(`row1.button.src`),
                          trackingTitle: t(`row1.button.title`),
                        })
                      }
                    >
                      {t('row1.button.label')}
                    </Button>
                  )}
                  {phase3 && !postEvent && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() => navigate(`/en/program/stage`)}
                    >
                      {t('teaser.agenda')}
                    </Button>
                  )}

                  {postEvent && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() => navigate(`/en/program/stage`)}
                    >
                      {t('teaser.recordings')}
                    </Button>
                  )}
                </div>
              }
            />
          </ContentContainer>
          <Spacer />
          <ContentContainer>
            <ContentColumn
              revertMobile
              right={<img src={t('row2.visual')} />}
              left={
                <div>
                  <Heading type="h4">{t('row2.header')}</Heading>
                  <Paragraph>
                    {parse(t(`row2.copy${postEvent ? 'PostEvent' : ''}`))}
                  </Paragraph>
                  {phase3 && !postEvent && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() => navigate(`/en/program/techmeetups`)}
                    >
                      {t('teaser.techMeetups')}
                    </Button>
                  )}

                  {postEvent && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() => navigate(`/en/showroom#guided-tour`)}
                    >
                      {t('teaser.guidedTourPostEvent')}
                    </Button>
                  )}
                </div>
              }
            />
          </ContentContainer>
          <Spacer />
          <ContentContainer>
            <ContentColumn
              left={<img src={t('row3.visual')} />}
              right={
                <div>
                  <Heading type="h4">{t('row3.header')}</Heading>
                  <Paragraph>
                    {parse(t(`row3.copy${postEvent ? 'PostEvent' : ''}`))}
                  </Paragraph>
                  {phase2 && !phase3 && !postEvent && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() => navigate(`/en/program/guidedtours`)}
                    >
                      {t('teaser.guidedTours')}
                    </Button>
                  )}

                  {phase3 && !postEvent && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() => navigate(`/en/showroom`)}
                    >
                      {t('teaser.showroom')}
                    </Button>
                  )}

                  {postEvent && (
                    <Button
                      style={{
                        padding: '18px ',
                        marginLeft: '-18px',
                      }}
                      tertiary
                      icon={SieArrow}
                      iconAlign="right"
                      onClick={() => navigate(`/en/showroom`)}
                    >
                      {t('teaser.showroom')}
                    </Button>
                  )}
                </div>
              }
            />
          </ContentContainer>
          <Spacer />
        </>
      )}
    </PageFrame>
  )
}

export default IndexPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
}

const StartTeaser = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    margin-top:100px;
    ${[mq[2]]}{
      margin-top:0px;
      display:flex;
      align-items:center;
    }`
  }

  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
